import { Province as province } from "@common/types/Province";
import { LocalDate } from "@js-joda/core";
import Location from "@modules/locations/types/Location";

export type ICAIncomeRange = {
  AnnualIncomeLower: number;
  AnnualIncomeUpper: number | null;
};

export enum InputKeys {
  YearsAtCurrentAddress = "yearsAtCurrentAddress",
  ClosestLocation = "closestLocation",
  FirstName = "firstName",
  PhoneNumber = "phoneNumber",
  AnnualIncome = "annualIncome",
  DateOfBirth = "dateOfBirth",
  LastName = "lastName",
  PostalCode = "postalCode",
  Email = "email",
  Province = "province",
  StreetAddress = "streetAddress",
  Address2 = "address2",
  City = "city",
  SelectedBudget = "selectedBudget",
  SelectedCreditScore = "selectedCreditScore",
  EmployerName = "employerName",
  JobTitle = "jobTitle",
  SelectedEmploymentStatus = "selectedEmploymentStatus",
  SelectedEmploymentLength = "selectedEmploymentLength",
  EmploymentYears = "employmentYears",
  EmploymentMonths = "employmentMonths",
  SelectedHomeLength = "selectedHomeLength",
  HomeYears = "homeYears",
  HomeMonths = "homeMonths",
  SelectedHomeStatus = "selectedHomeStatus",
  HomePayment = "homePayment",
  AnnualIncomeRange = "annualIncomeRange",
  SelectedTradeInResponse = "selectedTradeInResponse",
  SelectedBodyStyle = "selectedBodyStyle",
}

export enum InputActions {
  OnChangeFirstName,
  OnChangeLastName,
  OnChangeEmail,
  OnChangePhoneNumber,
  OnChangeAnnualIncome,
  OnChangeDateOfBirth,
  OnChangeStreetAddress,
  OnChangeAddress2,
  OnChangeCity,
  OnChangePostalCode,
  OnChangeProvince,
  OnChangeYearsAtCurrentAddress,
  OnChangeClosestLocation,
  OnChangeSelectedBudget,
  OnChangeSelectedCreditScore,
  OnChangeEmployerName,
  OnChangeJobTitle,
  OnChangeSelectedEmploymentStatus,
  OnChangeSelectedEmploymentLength,
  OnChangeEmploymentYears,
  OnChangeEmploymentMonths,
  OnChangeSelectedHomeLength,
  OnChangeHomeYears,
  OnChangeHomeMonths,
  OnChangeSelectedHomeStatus,
  OnChangeHomePayment,
  OnChangeAnnualIncomeRange,
  OnChangeSelectedTradeInResponse,
  OnChangeSelectedBodyStyle,
  ResetInputs,
}

export type YearsAtCurrentAddress = {
  id: string;
  name: string;
};

export interface FirstNameInputState {
  firstName: string;
}

export interface LastNameInputState {
  lastName: string;
}

export interface PhoneNumberInputState {
  phoneNumber: string;
}

export interface AnnualIncomeInputState {
  annualIncome: string;
}

export interface DateOfBirthInputState {
  dateOfBirth: LocalDate;
}

export interface YearsAtCurrentAddressInputState {
  yearsAtCurrentAddress: YearsAtCurrentAddress;
}

export interface ClosestLocationInputState {
  closestLocation: Location;
}

export type FirstNameInput = {
  key: InputKeys.FirstName;
  state: FirstNameInputState;
};

export type LastNameInput = {
  key: InputKeys.LastName;
  state: LastNameInputState;
};

export type PhoneNumberInput = {
  key: InputKeys.PhoneNumber;
  state: PhoneNumberInputState;
};

export type AnnualIncomeInput = {
  key: InputKeys.AnnualIncome;
  state: AnnualIncomeInputState;
};

export type DateOfBirthInput = {
  key: InputKeys.DateOfBirth;
  state: DateOfBirthInputState;
};

export type StreetAddressInputState = {
  streetAddress: string;
};

export type StreetAddressInput = {
  key: InputKeys.StreetAddress;
  state: StreetAddressInputState;
};

export type Address2InputState = {
  address2: string;
};

export type Address2Input = {
  key: InputKeys.Address2;
  state: Address2InputState;
};

export type CityInputState = {
  city: string;
};

export type CityInput = {
  key: InputKeys.City;
  state: CityInputState;
};

export type PostalCodeInputState = {
  postalCode: string;
};

export type PostalCodeInput = {
  key: InputKeys.PostalCode;
  state: PostalCodeInputState;
};

export type ProvinceInputState = {
  province: province;
};

export type ProvinceInput = {
  key: InputKeys.Province;
  state: ProvinceInputState;
};

export interface EmailInputState {
  email: string;
}

export type EmailInput = {
  key: InputKeys.Email;
  state: EmailInputState;
};

export type YearsAtCurrentAddressInput = {
  key: InputKeys.YearsAtCurrentAddress;
  state: YearsAtCurrentAddressInputState;
};

export type ClosestLocationInput = {
  key: InputKeys.ClosestLocation;
  state: ClosestLocationInputState;
};

export type SelectedBudgetInputState = {
  selectedBudget: string;
};

export type SelectedBudgetInput = {
  key: InputKeys.SelectedBudget;
  state: SelectedBudgetInputState;
};

export type SelectedCreditScoreInputState = {
  selectedCreditScore: string;
};

export type SelectedCreditScoreInput = {
  key: InputKeys.SelectedCreditScore;
  state: SelectedCreditScoreInputState;
};

export type EmployerNameInputState = {
  employerName: string;
};

export type EmployerNameInput = {
  key: InputKeys.EmployerName;
  state: EmployerNameInputState;
};

export type JobTitleInputState = {
  jobTitle: string;
};

export type JobTitleInput = {
  key: InputKeys.JobTitle;
  state: JobTitleInputState;
};

export type SelectedEmploymentStatusInputState = {
  selectedEmploymentStatus: string;
};

export type SelectedEmploymentStatusInput = {
  key: InputKeys.SelectedEmploymentStatus;
  state: SelectedEmploymentStatusInputState;
};

export type SelectedEmploymentLengthInputState = {
  selectedEmploymentLength: string;
};

export type SelectedEmploymentLengthInput = {
  key: InputKeys.SelectedEmploymentLength;
  state: SelectedEmploymentLengthInputState;
};

export type EmploymentYearsInputState = {
  employmentYears: number;
};

export type EmploymentYearsInput = {
  key: InputKeys.EmploymentYears;
  state: EmploymentYearsInputState;
};

export type EmploymentMonthsInputState = {
  employmentMonths: number;
};

export type EmploymentMonthsInput = {
  key: InputKeys.EmploymentMonths;
  state: EmploymentMonthsInputState;
};

export type SelectedHomeLengthInputState = {
  selectedHomeLength: string;
};

export type SelectedHomeLengthInput = {
  key: InputKeys.SelectedHomeLength;
  state: SelectedHomeLengthInputState;
};

export type HomeYearsInputState = {
  homeYears: number;
};

export type HomeYearsInput = {
  key: InputKeys.HomeYears;
  state: HomeYearsInputState;
};

export type HomeMonthsInputState = {
  homeMonths: number;
};

export type HomeMonthsInput = {
  key: InputKeys.HomeMonths;
  state: HomeMonthsInputState;
};

export type SelectedHomeStatusInputState = {
  selectedHomeStatus: string;
};

export type SelectedHomeStatusInput = {
  key: InputKeys.SelectedHomeStatus;
  state: SelectedHomeStatusInputState;
};

export type HomePaymentInputState = {
  homePayment: number;
};

export type HomePaymentInput = {
  key: InputKeys.HomePayment;
  state: HomePaymentInputState;
};

export type AnnualIncomeRangeInputState = {
  annualIncomeRange: ICAIncomeRange;
};

export type AnnualIncomeRangeInput = {
  key: InputKeys.AnnualIncomeRange;
  state: AnnualIncomeRangeInputState;
};

export type SelectedTradeInResponseInputState = {
  selectedTradeInResponse: string;
};

export type SelectedTradeInResponseInput = {
  key: InputKeys.SelectedTradeInResponse;
  state: SelectedTradeInResponseInputState;
};

export type SelectedBodyStyleInputState = {
  selectedBodyStyle: string;
};

export type SelectedBodyStyleInput = {
  key: InputKeys.SelectedBodyStyle;
  state: SelectedBodyStyleInputState;
};

export type CreditAppInput =
  | FirstNameInput
  | LastNameInput
  | PhoneNumberInput
  | AnnualIncomeInput
  | DateOfBirthInput
  | StreetAddressInput
  | Address2Input
  | CityInput
  | PostalCodeInput
  | ProvinceInput
  | EmailInput
  | YearsAtCurrentAddressInput
  | ClosestLocationInput
  | SelectedBudgetInput
  | SelectedCreditScoreInput
  | EmployerNameInput
  | JobTitleInput
  | SelectedEmploymentStatusInput
  | SelectedEmploymentLengthInput
  | EmploymentYearsInput
  | EmploymentMonthsInput
  | SelectedHomeLengthInput
  | HomeYearsInput
  | HomeMonthsInput
  | SelectedHomeStatusInput
  | HomePaymentInput
  | AnnualIncomeRangeInput
  | SelectedTradeInResponseInput
  | SelectedBodyStyleInput;

export type InputAction =
  | {
      type: InputActions.OnChangeFirstName;
      payload: FirstNameInputState;
    }
  | {
      type: InputActions.OnChangeEmail;
      payload: EmailInputState;
    }
  | {
      type: InputActions.OnChangeLastName;
      payload: LastNameInputState;
    }
  | {
      type: InputActions.OnChangePhoneNumber;
      payload: PhoneNumberInputState;
    }
  | {
      type: InputActions.OnChangeAnnualIncome;
      payload: AnnualIncomeInputState;
    }
  | {
      type: InputActions.OnChangeDateOfBirth;
      payload: DateOfBirthInputState;
    }
  | {
      type: InputActions.OnChangeStreetAddress;
      payload: StreetAddressInputState;
    }
  | {
      type: InputActions.OnChangeAddress2;
      payload: Address2InputState;
    }
  | {
      type: InputActions.OnChangeCity;
      payload: CityInputState;
    }
  | {
      type: InputActions.OnChangePostalCode;
      payload: PostalCodeInputState;
    }
  | {
      type: InputActions.OnChangeProvince;
      payload: ProvinceInputState;
    }
  | {
      type: InputActions.OnChangeYearsAtCurrentAddress;
      payload: YearsAtCurrentAddressInputState;
    }
  | {
      type: InputActions.OnChangeClosestLocation;
      payload: ClosestLocationInputState;
    }
  | {
      type: InputActions.OnChangeSelectedBudget;
      payload: SelectedBudgetInputState;
    }
  | {
      type: InputActions.OnChangeSelectedCreditScore;
      payload: SelectedCreditScoreInputState;
    }
  | {
      type: InputActions.OnChangeEmployerName;
      payload: EmployerNameInputState;
    }
  | {
      type: InputActions.OnChangeJobTitle;
      payload: JobTitleInputState;
    }
  | {
      type: InputActions.OnChangeSelectedEmploymentStatus;
      payload: SelectedEmploymentStatusInputState;
    }
  | {
      type: InputActions.OnChangeSelectedEmploymentLength;
      payload: SelectedEmploymentLengthInputState;
    }
  | {
      type: InputActions.OnChangeEmploymentYears;
      payload: EmploymentYearsInputState;
    }
  | {
      type: InputActions.OnChangeEmploymentMonths;
      payload: EmploymentMonthsInputState;
    }
  | {
      type: InputActions.OnChangeSelectedHomeLength;
      payload: SelectedHomeLengthInputState;
    }
  | {
      type: InputActions.OnChangeHomeYears;
      payload: HomeYearsInputState;
    }
  | {
      type: InputActions.OnChangeHomeMonths;
      payload: HomeMonthsInputState;
    }
  | {
      type: InputActions.OnChangeSelectedHomeStatus;
      payload: SelectedHomeStatusInputState;
    }
  | {
      type: InputActions.OnChangeHomePayment;
      payload: HomePaymentInputState;
    }
  | {
      type: InputActions.OnChangeAnnualIncomeRange;
      payload: AnnualIncomeRangeInputState;
    }
  | {
      type: InputActions.OnChangeSelectedTradeInResponse;
      payload: SelectedTradeInResponseInputState;
    }
  | {
      type: InputActions.OnChangeSelectedBodyStyle;
      payload: SelectedBodyStyleInputState;
    }
  | {
      type: InputActions.ResetInputs;
      payload: undefined;
    };

export default {};
