export const cleanParagraphTags = (title: string) => {
  if (title != null) {
    return title.replace(/<p>|<\/p>/g, "").trim();
  }
  return "";
};

export const cleanHtml = (title: string) => {
  if (title != null) {
    return title
      .replace(
        /<p>|<\/p>|<h1>|<\/h1>|<h2>|<\/h2>|<h3>|<\/h3>|<h4>|<\/h4>|<h5>|<\/h5>|<h6>|<\/h6>/g,
        ""
      )
      .trim();
  }
  return "";
};

export default {};
