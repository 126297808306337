import { useReducer } from "react";

import {
  CreditAppInput,
  InputAction,
  InputKeys,
  InputActions,
} from "../types/creditAppInput";

const reducer = (
  creditAppInputs: CreditAppInput[],
  action: InputAction
): CreditAppInput[] => {
  switch (action.type) {
    case InputActions.OnChangeFirstName: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.FirstName),
        { key: InputKeys.FirstName, state: action.payload },
      ];
    }
    case InputActions.OnChangeLastName: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.LastName),
        { key: InputKeys.LastName, state: action.payload },
      ];
    }
    case InputActions.OnChangeEmail: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.Email),
        { key: InputKeys.Email, state: action.payload },
      ];
    }
    case InputActions.OnChangePhoneNumber: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.PhoneNumber),
        { key: InputKeys.PhoneNumber, state: action.payload },
      ];
    }
    case InputActions.OnChangeAnnualIncome: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.AnnualIncome),
        { key: InputKeys.AnnualIncome, state: action.payload },
      ];
    }
    case InputActions.OnChangeDateOfBirth: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.DateOfBirth),
        { key: InputKeys.DateOfBirth, state: action.payload },
      ];
    }
    case InputActions.OnChangeStreetAddress: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.StreetAddress),
        { key: InputKeys.StreetAddress, state: action.payload },
      ];
    }
    case InputActions.OnChangeAddress2: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.Address2),
        { key: InputKeys.Address2, state: action.payload },
      ];
    }
    case InputActions.OnChangeCity: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.City),
        { key: InputKeys.City, state: action.payload },
      ];
    }
    case InputActions.OnChangeProvince: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.Province),
        { key: InputKeys.Province, state: action.payload },
      ];
    }
    case InputActions.OnChangePostalCode: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.PostalCode),
        { key: InputKeys.PostalCode, state: action.payload },
      ];
    }
    case InputActions.OnChangeYearsAtCurrentAddress: {
      return [
        ...creditAppInputs.filter(
          (f) => f.key !== InputKeys.YearsAtCurrentAddress
        ),
        { key: InputKeys.YearsAtCurrentAddress, state: action.payload },
      ];
    }
    case InputActions.OnChangeClosestLocation: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.ClosestLocation),
        { key: InputKeys.ClosestLocation, state: action.payload },
      ];
    }
    case InputActions.OnChangeSelectedBudget: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.SelectedBudget),
        { key: InputKeys.SelectedBudget, state: action.payload },
      ];
    }
    case InputActions.OnChangeSelectedCreditScore: {
      return [
        ...creditAppInputs.filter(
          (f) => f.key !== InputKeys.SelectedCreditScore
        ),
        { key: InputKeys.SelectedCreditScore, state: action.payload },
      ];
    }

    case InputActions.OnChangeEmployerName: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.EmployerName),
        { key: InputKeys.EmployerName, state: action.payload },
      ];
    }
    case InputActions.OnChangeJobTitle: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.JobTitle),
        { key: InputKeys.JobTitle, state: action.payload },
      ];
    }
    case InputActions.OnChangeSelectedEmploymentStatus: {
      return [
        ...creditAppInputs.filter(
          (f) => f.key !== InputKeys.SelectedEmploymentStatus
        ),
        { key: InputKeys.SelectedEmploymentStatus, state: action.payload },
      ];
    }
    case InputActions.OnChangeSelectedEmploymentLength: {
      return [
        ...creditAppInputs.filter(
          (f) => f.key !== InputKeys.SelectedEmploymentLength
        ),
        { key: InputKeys.SelectedEmploymentLength, state: action.payload },
      ];
    }
    case InputActions.OnChangeEmploymentYears: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.EmploymentYears),
        { key: InputKeys.EmploymentYears, state: action.payload },
      ];
    }
    case InputActions.OnChangeEmploymentMonths: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.EmploymentMonths),
        { key: InputKeys.EmploymentMonths, state: action.payload },
      ];
    }
    case InputActions.OnChangeSelectedHomeLength: {
      return [
        ...creditAppInputs.filter(
          (f) => f.key !== InputKeys.SelectedHomeLength
        ),
        { key: InputKeys.SelectedHomeLength, state: action.payload },
      ];
    }
    case InputActions.OnChangeHomeYears: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.HomeYears),
        { key: InputKeys.HomeYears, state: action.payload },
      ];
    }
    case InputActions.OnChangeHomeMonths: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.HomeMonths),
        { key: InputKeys.HomeMonths, state: action.payload },
      ];
    }
    case InputActions.OnChangeSelectedHomeStatus: {
      return [
        ...creditAppInputs.filter(
          (f) => f.key !== InputKeys.SelectedHomeStatus
        ),
        { key: InputKeys.SelectedHomeStatus, state: action.payload },
      ];
    }
    case InputActions.OnChangeHomePayment: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.HomePayment),
        { key: InputKeys.HomePayment, state: action.payload },
      ];
    }
    case InputActions.OnChangeAnnualIncomeRange: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.AnnualIncomeRange),
        { key: InputKeys.AnnualIncomeRange, state: action.payload },
      ];
    }
    case InputActions.OnChangeSelectedTradeInResponse: {
      return [
        ...creditAppInputs.filter(
          (f) => f.key !== InputKeys.SelectedTradeInResponse
        ),
        { key: InputKeys.SelectedTradeInResponse, state: action.payload },
      ];
    }
    case InputActions.OnChangeSelectedBodyStyle: {
      return [
        ...creditAppInputs.filter((f) => f.key !== InputKeys.SelectedBodyStyle),
        { key: InputKeys.SelectedBodyStyle, state: action.payload },
      ];
    }
    case InputActions.ResetInputs: {
      return [];
    }
    default: {
      throw Error(`Invalid action type: ${(action as InputAction).type}`);
    }
  }
};

export default function useCreditAppInputsReducer() {
  return useReducer(reducer, []);
}
