export function removeTrailingBackslash(value: string): string {
  return value.replace(/\/$/, "");
}

export function truncateFullWords(value: string, maxLength: number) {
  if (value.length <= maxLength) return value;
  const lastSpace = value.lastIndexOf(" ", maxLength);
  return value.slice(0, lastSpace === -1 ? maxLength : lastSpace);
}

export function formatPhoneNumber(value: string): string {
  if (!value) {
    return value;
  }

  const cleaned = value.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return value;
}

export function formatPhoneNumberForLink(value: string): string {
  if (!value) {
    return value;
  }

  const cleaned = value.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}.${match[2]}.${match[3]}`;
  }
  return value;
}

export function capitalizeFirstLetter(value: string): string {
  return value.toLowerCase().charAt(0).toUpperCase() + value.slice(1);
}
