import { ComponentProps } from "react";
import dynamic from "next/dynamic";
import SaveSearchModal from "./SaveSearchModal";
import SaveToFavouritesModal from "./SaveToFavouritesModal";

export const DynamicSaveSearchModal = dynamic<
  ComponentProps<typeof SaveSearchModal>
>(() => import("./SaveSearchModal").then((module) => module.default));

export const DynamicSaveToFavouritesModal = dynamic<
  ComponentProps<typeof SaveToFavouritesModal>
>(() => import("./SaveToFavouritesModal").then((module) => module.default));
