import { createContext, Dispatch, SetStateAction } from "react";
import { BaseVehicleDetail } from "@modules/vehicle/types/VehicleDetail";
import { CreditAppInput, InputAction } from "./types/creditAppInput";
import { CreditAppModalMode } from "./types/CreditAppModalMode";

export type CreditAppModalContextState = {
  setCreditAppShowModal(value: boolean): void;
  creditAppInputs: CreditAppInput[];
  creditAppDispatch: Dispatch<InputAction>;
  creditAppModeState: [
    CreditAppModalMode,
    Dispatch<SetStateAction<CreditAppModalMode>>
  ];
  creditAppIdState: [string, Dispatch<SetStateAction<string>>];
  vehicleDetail?: [
    BaseVehicleDetail | undefined,
    Dispatch<SetStateAction<BaseVehicleDetail | undefined>>
  ];
};

const CreditAppModalContext = createContext<CreditAppModalContextState>(
  {} as CreditAppModalContextState
);

export default CreditAppModalContext;
