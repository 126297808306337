import { Dispatch, createContext, SetStateAction } from "react";

export interface SaveToFavouritesModalContextState {
  showModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  limitExceededState: [boolean, Dispatch<SetStateAction<boolean>>];
}

const SaveToFavouritesModalContext =
  createContext<SaveToFavouritesModalContextState>(
    {} as SaveToFavouritesModalContextState
  );

export default SaveToFavouritesModalContext;
