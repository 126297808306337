export enum ModeName {
  introduction = "introduction",
  personalInfo = "creditAppPersonalInfo",
  residenceInfo = "residenceInfo",
  submitAndReview = "submitAndReview",
  successSubmission = "successSubmission",
  successSubmissionPrime = "successSubmissionPrime",
}

export type CreditAppModalMode = {
  name: ModeName;
  modalHeaderTextKey: string;
};

export const introductionMode: CreditAppModalMode = {
  name: ModeName.introduction,
  modalHeaderTextKey: "right_payment_application",
};

export const personalInfoMode: CreditAppModalMode = {
  name: ModeName.personalInfo,
  modalHeaderTextKey: "right_payment_application",
};

export const residenceinfoMode: CreditAppModalMode = {
  name: ModeName.residenceInfo,
  modalHeaderTextKey: "right_payment_application",
};

export const submitAndReviewMode: CreditAppModalMode = {
  name: ModeName.submitAndReview,
  modalHeaderTextKey: "right_payment_application",
};

export const successSubmissionMode: CreditAppModalMode = {
  name: ModeName.successSubmission,
  modalHeaderTextKey: "right_payment_application",
};

export const successSubmissionPrimeMode: CreditAppModalMode = {
  name: ModeName.successSubmissionPrime,
  modalHeaderTextKey: "right_payment_application",
};
